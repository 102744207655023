import React, { useEffect, CSSProperties, useState } from 'react';
import { images, spacing, color } from '../Layouts/theme';
import { SPACE_BETWEEN_CONTENT, BUTTON_STYLE } from '../Layouts/styled/main';
import { useTranslation } from 'react-i18next';
import ContentImageCenter from '../components/card-content/content-image-center';
import ContentImageLeft from '../components/card-content/content-image-left';
import ContentImageRight from '../components/card-content/content-image-right';

const WhyUs = () => {
  const { t } = useTranslation();
  const [seeMores, setSeeMores] = useState([false, false, false]);



  const onChangeSeeMore = (index: number) => {
    let see = JSON.parse(JSON.stringify(seeMores));
    see[index] = true;
    setSeeMores(see);
  };

  return (
    <main className={'main'}>
      {/* //? section 1 */}
      <ContentImageCenter
        img={{ src: images.whyUsHeaderImage, alt: 'why-us-heder-image' }}
        title_name={t('why_us_main_title')}
        content={t('why_us_main_subtitle')}
      />

      {/* //? section 2 */}
      <ContentImageLeft
        img={{ src: images.whyUsImage1, alt: 'why-us-image-1' }}
        title_name={t('raw_material')}
        content={t('why_us_material_description')}
      />

      {/* //? section 3 */}
      <div style={SPACE_BETWEEN_CONTENT} />
      <ContentImageRight
        img={{ src: images.whyUsImage2, alt: 'why-us-image-2' }}
        title_name={t('high_standard_product')}
        content={
          seeMores[1]
            ? t('why_us_product_description')
            : t('why_us_product_abbreviation')
        }
        is_more_detail={!seeMores[1]}
        detail_name={t('see_more')}
        onClick={() => onChangeSeeMore(1)}
      />

      {/* //? section 4 */}
      <div style={SPACE_BETWEEN_CONTENT} />
      <ContentImageLeft
        img={{ src: images.whyUsImage3, alt: 'why-us-image-3' }}
        title_name={t('quality_control')}
        content={
          seeMores[2]
            ? t('why_us_quality_description')
            : t('why_us_quality_abbreviation')
        }
        is_more_detail={!seeMores[2]}
        detail_name={t('see_more')}
        onClick={() => onChangeSeeMore(2)}
      />

      <div style={SPACE_BETWEEN_CONTENT} />
    </main>
  );
};
export default WhyUs;
