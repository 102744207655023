import React, { useEffect, CSSProperties, useState, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { images, spacing, color } from '../../Layouts/theme';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
  CONTENT_CENTER,
  ImageHorizontal,
  CONTENT_CARD,
  BUTTON_STYLE,
  ImageMobileBox,
} from '../../Layouts/styled/main';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ContentProps } from './content.props';
import styled from 'styled-components';
// import Img from "gatsby-image/withIEPolyfill"
import Img from 'gatsby-image';

const ColBox = styled(Col)`
  padding-right: 0px !important;
  padding-left: 0px !important;
  @media (max-width: 576px) {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
`;

const ContentImageCenter: React.FC<ContentProps> = ({
  img,
  title_name,
  content,
  is_more_detail,
  source,
  headerMediumStyle,
  headerSmallStyle,
  contentMediumStyle,
  contentSmallStyle
}) => {
  const { t } = useTranslation();

  const HEDAER_STYLE: CSSProperties = {
    ...(i18next?.language === 'th' ? HEADER_TH : HEADER_EN),
    textAlign: 'center',
  };

  const BODY_STYLE: CSSProperties = {
    ...(i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN),
    textAlign: 'center',
  };

  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    textDecoration: 'none',
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  return (
    <Fragment>
      <section className="is-not-hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <ColBox xs="12">
              <ImageMobileBox data-aos="fade-up">
                {/* <ImageHorizontal src={img?.src} alt={img?.alt || ''} /> */}
                <Img
                  fluid={source}
                  alt="ouay-shop-header-image"
                  style={{
                    position: undefined,
                  }}
                  imgStyle={{
                    borderRadius: '0px 80px',
                    left: 'auto',
                  }}
                />
              </ImageMobileBox>
            </ColBox>
          </Row>
        </Container>
      </section>
      <section className="hd-size">
        <Container fluid>
          <Row
            className="justify-content-center"
            style={{ height: '50%', backgroundColor: color.backgroundWhite }}
          >
            <Col style={CONTENT_CENTER}>
              <div data-aos="fade-up" style={{ width: 1050, height: 480 }}>
                {/* <ImageHorizontal src={img?.src} alt={img?.alt || ''} /> */}
                <Img
                  fluid={source}
                  alt="ouay-shop-header-image"
                  style={{
                    position: undefined,
                  }}
                  imgStyle={{
                    borderRadius: '0px 110px',
                    left: 'auto',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <Container fluid style={{ paddingTop: 60, paddingBottom: 50 }}>
          <Row className="justify-content-center">
            <Col xs="10" sm="8" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  dangerouslySetInnerHTML={{ __html: title_name }}
                  style={{
                    ...(i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN),
                    textAlign: 'center',
                    ...headerSmallStyle
                  }}
                />
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{
                    ...BODY_STYLE,
                    ...contentSmallStyle
                  }}
                />
              </div>
            </Col>
          </Row>
          {is_more_detail && (
            <>
              <br />
              <Row className="justify-content-center">
                <Col xs="auto">
                  <a
                    target="_blank"
                    href="https://ouayun.bentoweb.com/th"
                    style={BUTTON}
                  >
                    {t('online_shop')}
                  </a>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>
      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container fluid style={CONTENT_CARD}>
          <Row className="justify-content-center">
            <Col md="7" style={{ ...CONTENT_CENTER, maxWidth: 650 }}>
              <div data-aos="fade-up">
                <div
                  dangerouslySetInnerHTML={{ __html: title_name }}
                  style={{
                    ...HEDAER_STYLE,
                    ...headerMediumStyle
                  }}
                />
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{
                    ...BODY_STYLE,
                    ...contentMediumStyle,
                  }}
                />
              </div>
            </Col>
          </Row>
          {is_more_detail && (
            <>
              <br />
              <br />
              <Row className="justify-content-center">
                <Col xs="auto">
                  <a
                    target="_blank"
                    href="https://ouayun.bentoweb.com/th"
                    style={BUTTON}
                  >
                    {t('online_shop')}
                  </a>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>
    </Fragment>
  );
};
export default ContentImageCenter;
