import React, { useEffect, useState, CSSProperties } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
  NON_PADDING_LEFT_RIGHT,
  MOBILE_CONTENT_CARD,
  RightImage,
  BUTTON_STYLE,
  ImageMobileBox,
} from '../../Layouts/styled/main';
import { color, spacing } from '../../Layouts/theme';
import i18next from 'i18next';
import { ContentProps } from './content.props';
import Img from 'gatsby-image';

const IMAGE_STYLE: CSSProperties = {
  backgroundColor: color.mainColor3,
};

const ContentImageRight: React.FC<ContentProps> = ({
  img,
  title_name,
  content,
  is_more_detail,
  detail_name,
  source,
  onClick,
  content_padding_top,
}) => {


  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  return (
    <>
      <section className="is-not-hd-size">
        {/* //? for screen size xs-md */}
        <Container fluid className="d-md-none d-block">
          <Row className="justify-content-center">
            <Col xs="12">
              <ImageMobileBox data-aos="fade-right">
                {/* <RightImage
                  src={img?.src}
                  style={IMAGE_STYLE}
                  alt={img?.alt || ''}
                /> */}
                <Img
                  fluid={source}
                  alt="ouay-article-1-image"
                  style={{
                    position: undefined,
                  }}
                  imgStyle={{
                    borderRadius: '80px 0px',
                    left: 'auto',
                  }}
                />
              </ImageMobileBox>
            </Col>

            <Col xs="10">
              <Container
                fluid
                style={{ paddingTop: 40, alignContent: 'center' }}
              >
                <Row>
                  <Col xs="12" className="text-spacing-l-r">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{ __html: title_name }}
                        style={
                          i18next?.language === 'th'
                            ? MOBILE_HEADER_TH
                            : MOBILE_HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{ __html: content }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {is_more_detail && (
                  <>
                    <br />
                    <Row>
                      <Col xs="12" className="text-spacing-l-r">
                        <button style={BUTTON} onClick={onClick}>
                          {detail_name}
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Container>

        {/* //? for screen size md++ */}
        <Container fluid className="d-none d-md-block" style={{ overflowX: 'hidden' }}>
          <Row className="justify-content-center">
            <Col xs="1" />
            <Col md="4">
              <Container
                fluid
                style={{
                  paddingTop: content_padding_top,
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col xs="12">
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{ __html: title_name }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{ __html: content }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {is_more_detail && (
                  <>
                    <br />
                    <Row>
                      <Col xs="12">
                        <button style={BUTTON} onClick={onClick}>
                          {detail_name}
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
            <Col xs="1" />

            <Col md="6" style={{ paddingRight: spacing[0] }}>
              <div
                data-aos="fade-left"
                style={{ width: '100%', height: 'auto' }}
              >
                {/* <RightImage
                  src={img?.src}
                  style={IMAGE_STYLE}
                  alt={img?.alt || ''}
                /> */}
                <Img
                  fluid={source}
                  alt="ouay-article-1-image"
                  style={{
                    position: 'relative',
                  }}
                  imgStyle={{
                    borderRadius: '110px 0px',
                    left: 'auto',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="hd-size">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xs="auto" style={{ width: 480 }}>
              <Container
                fluid
                style={{
                  ...NON_PADDING_LEFT_RIGHT,
                  paddingTop: content_padding_top,
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col xs="auto" style={{ width: 120 }} />
                  <Col
                    xs="auto"
                    style={{ ...NON_PADDING_LEFT_RIGHT, width: 360 }}
                  >
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{ __html: title_name }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{ __html: content }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {is_more_detail && (
                  <>
                    <br />
                    <Row>
                      <Col xs="auto" style={{ width: 120 }} />
                      <Col
                        xs="auto"
                        style={{ ...NON_PADDING_LEFT_RIGHT, width: 360 }}
                      >
                        <button style={BUTTON} onClick={onClick}>
                          {detail_name}
                        </button>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
            <Col xs="auto" style={{ width: 120 }} />
            <Col
              xs="auto"
              data-aos="fade-right"
              style={{ width: 480, height: 480 }}
            >
              {/* <RightImage
                src={img?.src}
                style={IMAGE_STYLE}
                alt={img?.alt || ''}
              /> */}
              <Img
                fluid={source}
                alt="ouay-article-1-image"
                style={{
                  position: undefined,
                }}
                imgStyle={{
                  borderRadius: '110px 0px',
                  maxWidth: 450,
                  left: 'auto',
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ContentImageRight;
